import { useState, useEffect } from 'react';
import { httpRequest } from '@/services';

export default function LocationDetails() {
  const [detailedLocations, setDetailedLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [error, setError] = useState(null);
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  useEffect(() => {
    async function fetchLocationDetails() {
      try {
        const locationData = await httpRequest.get('api/locations');
        const detailedList = [];
        for (const location of locationData) {
          const settingsDetails = await httpRequest.get(
            `api/locationsettings/${location.locationID}`
          );
          if (settingsDetails.digitalSignageUrl.includes(subdomain)) {
            detailedList.push(settingsDetails);
          }
        }
        setDetailedLocations(detailedList);
      } catch (err) {
        console.error('Error fetching details:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchLocationDetails();
  }, []); 

  return [detailedLocations, isLoading]; 
}